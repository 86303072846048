import React, { Suspense, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate, RouteProps, useLocation } from 'react-router';
import { Role, userHasAccess } from 'models/Role';
import { Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout/MainLayout';
import MinimalLayout from 'layouts/MinimalLayout/MinimalLayout';
import Dashboard from 'views/Dashboard/Dashboard';
import RegisterPage from 'views/Auth/RegisterPage';
import LoginPage from 'views/Auth/LoginPage';
import PageLoader from 'components/Spinners/PageLoader';
import Alert from '@material-ui/lab/Alert';
import UserContext from 'state/UserContext';

const AdminPage = React.lazy(() => import('views/Administration/AdminPage'));
const JobsListPage = React.lazy(() => import('views/Jobs/JobListPage'));
const JobPage = React.lazy(() => import('views/Jobs/JobPage'));
const FormBuilderPage = React.lazy(() => import('views/FormBuilder'));
const EquipmentListPage = React.lazy(
  () => import('views/Equipment/EquipmentListPage')
);
const EquipmentCategoryPage = React.lazy(
  () => import('views/Equipment/EquipmentCategoryPage')
);
const CalendarPage = React.lazy(() => import('views/Calendar/CalendarPage'));
const ClientsPage = React.lazy(() => import('views/Clients/ClientsPage'));
const ClientDetailsPage = React.lazy(
  () => import('views/Clients/ClientDetailsPage')
);
const UserProfilePage = React.lazy(
  () => import('views/UserProfile/UserProfilePage')
);
const ForgotPasswordPage = React.lazy(
  () => import('views/Auth/ForgotPassword')
);
const ResetPasswordPage = React.lazy(() => import('views/Auth/ResetPassword'));
const ContactSupportPage = React.lazy(
  () => import('views/ContactSupport/ContactSupportPage')
);

function withLayout(Layout: any, Component: any) {
  return (
    <Layout>
      <Suspense
        fallback={
          <>
            <h1>[Loading...]</h1>
          </>
        }>
        <Component />
      </Suspense>
    </Layout>
  );
}

interface ProtectedRouteProps extends RouteProps {
  restrictTo?: Role[];
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  if (!user || user.status === 'error') {
    return (
      <Navigate to="/login" replace state={{ redirect: location.pathname }} />
    );
  }

  if (['loading', 'idle'].includes(user.status)) {
    return <PageLoader />;
  }

  if (user.status === 'success') {
    if (!props.restrictTo) {
      // No restrictions
      return props.element;
    } else if (userHasAccess(user, props.restrictTo)) {
      return props.element;
    }

    return (
      <MainLayout>
        <Alert
          severity="error"
          style={{ padding: '2rem', margin: '2rem' }}
          elevation={1}
          variant="outlined">
          <Typography variant="h4" gutterBottom>
            Access Denied!
          </Typography>
          <Typography variant="body1" gutterBottom>
            You do not have the required permissions to proceed to this page.
            <br />
            Only users with the following roles can access this page:
          </Typography>
          {props.restrictTo.map((role) => (
            <React.Fragment key={role}>
              <Typography variant="caption">&bull; {role}</Typography>
              <br />
            </React.Fragment>
          ))}
          <Typography variant="body2">
            If you do need access, please contact an Administrator from your
            Organization.
          </Typography>
        </Alert>
      </MainLayout>
    );
  }
  return null;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/register"
        element={withLayout(MinimalLayout, RegisterPage)}
      />
      <Route path="/login" element={withLayout(MinimalLayout, LoginPage)} />
      <Route
        path="/forgot-password"
        element={withLayout(MinimalLayout, ForgotPasswordPage)}
      />
      <Route
        path="/reset_password/:token"
        element={withLayout(MinimalLayout, ResetPasswordPage)}
      />
      <ProtectedRoute
        path="/"
        element={withLayout(MainLayout, Dashboard)}
        key="index"
      />
      <ProtectedRoute
        path="/administration/*"
        element={withLayout(MainLayout, AdminPage)}
        restrictTo={['Administrator']}
      />
      <ProtectedRoute
        path="/clients"
        element={withLayout(MainLayout, ClientsPage)}
        restrictTo={['Administrator', 'ProjectManager', 'Supervisor']}
      />
      <ProtectedRoute
        path="/clients/:id/*"
        element={withLayout(MainLayout, ClientDetailsPage)}
        restrictTo={['Administrator', 'ProjectManager', 'Supervisor']}
      />
      <ProtectedRoute
        path="/jobs"
        element={withLayout(MainLayout, JobsListPage)}
      />
      <ProtectedRoute
        path="/jobs/:id"
        element={withLayout(MainLayout, JobPage)}
      />
      <ProtectedRoute
        path="/jobs/:id/:tab"
        element={withLayout(MainLayout, JobPage)}
      />
      <ProtectedRoute
        path="/jobs/:id/:tab/:siteVisitID"
        element={withLayout(MainLayout, JobPage)}
      />
      <ProtectedRoute
        path="/equipment"
        element={withLayout(MainLayout, EquipmentListPage)}
        restrictTo={['Administrator']}
      />
      <ProtectedRoute
        path="/equipment/category/:id"
        element={withLayout(MainLayout, EquipmentCategoryPage)}
        restrictTo={['Administrator']}
      />
      <ProtectedRoute
        path="/calendar"
        element={withLayout(MainLayout, CalendarPage)}
      />
      <ProtectedRoute
        path="/users/profile/:id"
        element={withLayout(MainLayout, UserProfilePage)}
      />
      <ProtectedRoute
        path="/formbuilder"
        element={withLayout(MainLayout, FormBuilderPage)}
        restrictTo={['Administrator']}
      />
      <ProtectedRoute
        path="/contact-support"
        element={withLayout(MainLayout, ContactSupportPage)}
      />
    </Routes>
  );
};

export default AppRoutes;
