import Axios from 'axios';
import { ClientAPI } from 'api';
import { User } from './userApi';
import { Contact } from './clientsApi';
import { RoomFeedItem } from './inspectionsApi';
import { Organization } from './organizationApi';
import { z } from 'zod';
import { RecurringOptions } from 'components/ScheduledTasks/RecurringTasksOptions';
import base64ImageToBlob from 'util/base64ImageToBlob';
import { countryList } from 'util/countryAndStateUtil';

export enum StatusValue {
  NOT_STARTED = 'Not started',
  IN_PROGRESS = 'In progress',
  ON_HOLD = 'On hold',
  APPROVAL_PENDING = 'Approval pending',
  TO_BE_INVOICED = 'To be invoiced',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export interface Job {
  id?: string;
  isPrivateJob: boolean;
  client?: ClientAPI.Client;
  clientId?: string;
  clientContact?: Contact;
  clientContactId?: string;
  organizationId: number;
  policyHolderId: string;
  purchaseOrder?: string;
  claimNumber?: string;
  insuranceCompany?: string;
  jobNo?: string;
  projectManagerId: string;
  projectManager: User;
  supervisorId: string;
  supervisor: User;
  jobTechnicians?: { user: User }[];
  description?: string;
  startDate?: string;
  createdOn: string;
  updatedOn: string;
  type: string;
  siteAddress: string;
  contactName: string;
  contactEmail?: string;
  contactNumber?: string;
  currentStatus: JobStatus;
  statuses?: JobStatus[];
  tasks?: ScheduledTask[];
  identifier?: string;
  overviewImage?: JobOverviewImage;
  createdBy?: User;
  state: string;
  country: countryList;
}

export interface JobOverviewImage {
  id?: string;
  organizationId: number;
  createdOn: string;
  updatedOn: string;
  name: string;
  mimeType: string;
  createdByUserId: string;
  gcsPath: string;
  updatedByUserId: string;
  createdByUser?: User;
  updatedByUser?: User;
}
export interface JobStatus {
  id?: string;
  value: StatusValue;
  notes: string;
  createdOn: string;
  createdByUserId: string;
  createdByUser?: User;
}

export interface taskAssignment {
  userId: string;
  user?: User;
  scheduledTaskId: string;
  scheduledTask?: ScheduledTask;
  createdOn: StereoPannerNode;
  createdByUserId: string;
  createdByUser?: User;
}

export const ScheduledTaskAttachmentDetailsSchema = z.object({
  id: z.string(),
  taskId: z.string(),
  organizationId: z.string(),
  jobId: z.string().optional(),
  name: z.string().optional(),
  mimeType: z.string().optional(),
  createdOn: z.string().optional(),
  gcsPath: z.string().optional(),
});

export type ScheduledTaskAttachmentDetails = z.infer<
  typeof ScheduledTaskAttachmentDetailsSchema
>;

export interface ScheduledTask {
  id?: string;
  job?: Job;
  organization?: Organization;
  jobId?: string;
  name?: string;
  description?: string;
  updatedOn?: string;
  updatedByUser?: User;
  createdByUser?: User;
  createdByUserId?: string;
  taskAssignments?: taskAssignment[];
  assignedUserIds?: string[]; // Only on client side (POST data)
  start: string;
  end: string;
  attachments?: File[] | ScheduledTaskAttachmentDetails[];
  recurringData?: RecurringOptions;
  isRecurring?: boolean;
}
export interface Note {
  id?: number;
  jobId: string;
  text: string;
  updatedOn: string;
  updatedByUserId: string;
  updatedByUser: User;
}

export interface SearchParams {
  companyName?: string;
  status?: string;
  pmName?: string;
  technicianName?: string;
  q?: string; // search
}

export type JobListFilters = {
  clientId?: string;
  projectManagerId?: string;
  technicianId?: string;
  state?: string;
};

export async function listAllJobs(filters?: JobListFilters): Promise<{
  jobs: Job[];
  identifier: string;
  closedJobs: Job[];
  ongoingJobs: Job[];
}> {
  console.log('API invoked with filters: ', filters);
  const searchParams = new URLSearchParams(filters).toString();
  const res = await Axios.get(
    `/api/v1/jobs${searchParams && `?${searchParams}`}`
  );
  return res.data;
}

export async function listAllJobSummaries(): Promise<
  Pick<Job, 'id' | 'siteAddress' | 'claimNumber'>[]
> {
  const res = await Axios.get('/api/v1/jobs/list-summary');

  return res.data;
}

export async function getJobById(id: string): Promise<Job> {
  const res = await Axios.get(`/api/v1/jobs/${id}`);
  return res.data;
}

export interface CreateUpdateJob extends Job {
  technicianIds?: string[];
}

export async function createJob(data: CreateUpdateJob) {
  // console.log('Update job API called');
  const res = await Axios.post('/api/v2/jobs', data);
  return res.data;
}

export async function updateJob(id: string, data: Partial<CreateUpdateJob>) {
  console.log('Update job API called');
  const res = await Axios.put(`/api/v2/jobs/${id}`, data);
  return res.data;
}

export async function deleteJob(id: string) {
  await Axios.delete(`/api/v1/jobs/${id}`);
}

export async function updateJobStatus({
  id,
  data,
}: {
  id: string;
  data: JobStatus;
}) {
  const res = await Axios.post(`/api/v1/jobs/${id}/status`, data);
  return res.data;
}

export async function deleteJobStatus({ jobId, statusId }) {
  const res = await Axios.delete(`/api/v1/jobs/${jobId}/status/${statusId}`);
  return res.data;
}

export async function listNotes(jobId: string): Promise<Note[]> {
  const res = await Axios.get(`/api/v1/notes/${jobId}`);
  return res.data;
}

export async function createNote({ jobId, text }) {
  const res = await Axios.post(`/api/v1/notes/${jobId}`, { text });
  return res.data;
}

export async function deleteNote({ jobId, noteId }) {
  const res = await Axios.delete(`/api/v1/notes/${jobId}/${noteId}`);
  return res.data;
}

export async function listJobSMS(jobId: string) {
  const res = await Axios.get(`/api/v1/sms/${jobId}`);
  return res.data;
}

export async function sendSMS({ jobId, from, recipientNumber, text }) {
  const res = await Axios.post(`/api/v1/sms`, {
    recipientNumber,
    text,
    jobId,
    from,
  });
  return res.data;
}

export async function listJobEmails(jobId: string) {
  const res = await Axios.get(`/api/v1/email/${jobId}`);
  return res.data;
}

export async function getAttachmentPreviewLink(
  jobId: string,
  emailId: string,
  fileId: string
) {
  const res = await Axios.get(
    `/api/v1/email/${emailId}/job/${jobId}/${fileId}/url`
  );
  return res.data;
}

export async function sendJobEmail({ jobId, data }) {
  const formData = new FormData();

  const propertiesToAppend = [
    'bcc',
    'cc',
    'draftJsObjectBody',
    'forwardRepliesToEmail',
    'subject',
    'to',
  ];

  Object.keys(data).forEach((key) => {
    if (key === 'attachments') {
      data.attachments.forEach((file: File) =>
        formData.append('attachments', file)
      );
    } else if (propertiesToAppend.includes(key) && data[key]) {
      formData.append(key, JSON.stringify(data[key]));
    }
  });

  const res = await Axios.post(`/api/v1/email/${jobId}`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res.data;
}

export async function listJobInventoryByRoom(
  jobId: string,
  roomId: string
): Promise<RoomFeedItem[]> {
  const res = await Axios.get(`/api/v1/jobs/${jobId}/room/${roomId}/inventory`);
  return res.data;
}

export async function getJobStatus(jobId: string) {
  const res = await Axios.get(`/api/v1/jobs/status/${jobId}`);
  return res.data;
}

export async function uploadJobOverviewImage({
  id,
  image,
}: {
  id: string;
  image: string;
}) {
  const formData = new FormData();
  formData.append('jobOverviewImage', base64ImageToBlob(image));
  const res = await Axios.post(`/api/v1/jobs/${id}/overViewImage`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
  return res.data;
}

export async function updateJobOverviewImage({
  id,
  newImage,
}: {
  id: string;
  newImage: string;
}) {
  const formData = new FormData();
  formData.append('jobOverviewImage', base64ImageToBlob(newImage));
  const res = await Axios.put(`/api/v1/jobs/${id}/jobOverviewImage`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
  return res.data;
}

export async function deleteJobOverviewImage(id: string) {
  await Axios.delete(`/api/v1/jobs/${id}/jobOverviewImage`);
}

export async function getJobStateTimezone(jobId: string) {
  const res = await Axios.get(`/api/v2/jobs/${jobId}/timezone`);
  console.log('res', res.data);
  return res.data;
}
