import Axios from 'axios';
import download from 'downloadjs';

export enum ReportType {
  INVENTORY_REPORT = 'inventory-report',
  INITIAL_ATTENDANCE_REPORT = 'initial-attendance-report',
  PHOTO_REPORT = 'photo-report',
  DYNAMIC_REPORT = 'dynamic-report',
  SITE_VISIT_REPORT = 'site-visit-report',
  COMPLETION_REPORT = 'completion-report',
  EQUIPMENT_STATUSES_REPORT = 'equipment-statuses-report',
  //equipment-statuses-report
}

export async function downloadAttendanceReport(
  jobId: string,
  filename?: string,
  letterheadId?: string
): Promise<void> {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}`
  ).toString();

  const res = await Axios.get(
    `/api/v1/reports/${jobId}/${
      ReportType.INITIAL_ATTENDANCE_REPORT
    }${`?${searchParams}`}`,
    {
      responseType: 'arraybuffer',
    }
  );
  return res.data;
}

export async function downloadInventoryReport(
  jobId: string,
  filename: string,
  roomIds?: string[],
  dispositions?: string[]
): Promise<void> {
  const res = await Axios.post(
    `/api/v1/reports/${jobId}/${ReportType.INVENTORY_REPORT}`,
    { roomIds, dispositions },
    {
      responseType: 'arraybuffer',
    }
  );
  return res.data;
}

export async function downloadPhotoReport({
  jobId,
  feedItemIDs,
  letterheadId,
}: {
  jobId: string;
  feedItemIDs: any[];
  fileName?: string;
  letterheadId: string;
}) {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}`
  ).toString();

  const res = await Axios.post(
    `/api/v1/reports/${jobId}/${ReportType.PHOTO_REPORT}${`?${searchParams}`}`,
    { feedItemIDs },
    {
      responseType: 'arraybuffer',
    }
  );
  return res.data;
}

export async function downloadDynamicReport({
  jobId,
  formId,
  fileName,
  letterheadId,
}: {
  jobId: string;
  formId: string;
  fileName: string;
  letterheadId?: string;
}) {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}`
  ).toString();

  const res = await Axios.get(
    `/api/v1/reports/${jobId}/${
      ReportType.DYNAMIC_REPORT
    }/${formId}${`?${searchParams}`}`,
    {
      responseType: 'arraybuffer',
    }
  );

  const mime = res.headers['Content-Type'];
  download(res.data, fileName, mime);
}

export async function downloadSiteVisitReport({
  jobId,
  inspectionId,
  letterheadId,
}: {
  jobId: string;
  inspectionId: string;
  letterheadId: string;
}) {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}`
  ).toString();

  const res = await Axios.get(
    `/api/v1/reports/${jobId}/${inspectionId}/${
      ReportType.SITE_VISIT_REPORT
    }${`?${searchParams}`}`,
    {
      responseType: 'arraybuffer',
    }
  );

  return res.data;
}

export async function downloadCompletionReport({
  jobId,
  fileName,
  letterheadId,
}: {
  jobId: string;
  fileName?: string;
  letterheadId: string;
}) {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}`
  ).toString();

  const res = await Axios.get(
    `/api/v1/reports/${jobId}/${
      ReportType.COMPLETION_REPORT
    }${`?${searchParams}`}`,
    {
      responseType: 'arraybuffer',
    }
  );

  return res.data;
}

export async function downloadEquipmentStatusesReport({
  fileName,
}: {
  fileName: string;
}) {
  const res = await Axios.get(
    `/api/v1/reports/${ReportType.EQUIPMENT_STATUSES_REPORT}`,
    {
      responseType: 'arraybuffer',
    }
  );

  const mime = res.headers['Content-Type'];
  download(res.data, fileName, mime);
}
